<template>
  <b-card-code title="关键词回复列表">
    <!-- search input -->
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-login
      variant="outline-primary"
    >
      添加回复
    </b-button>
    <b-modal
      id="modal-login"
      cancel-variant="outline-secondary"
      ok-title="添加"
      cancel-title="关闭"
      centered
      no-close-on-backdrop
      title="添加回复内容"
      @ok="alert"
    >
      <b-form>
        <b-form-group label="部门名称" label-for="dname">
          <b-form-select
            v-model="roleSelect"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="roleOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="user-dname"
          />
        </b-form-group>
        <b-form-group>
          <label for="module">关键词:</label>
          <b-form-input
            id="module"
            placeholder="关键词"
            autocomplete="off"
            v-model="keyWord"
          />
        </b-form-group>
        <b-form-group>
          <div style="float: left; width: 80px; margin-top: 10px">
            <label label-for="nickname">匹配类型：</label>
          </div>
          <div style="float: left; padding-top: 12px">
            <b-form-radio v-model="keyType" name="keyType" value="0"
              >精准
            </b-form-radio>
          </div>
          <div style="float: left; padding-top: 12px; margin-left: 50px">
            <b-form-radio v-model="keyType" name="keyType" value="1">
              模糊
            </b-form-radio>
          </div>
        </b-form-group>
        <b-form-group>
          <div style="float: left; width: 80px; margin-top: 10px">
            <label label-for="nickname">人群类型：</label>
          </div>
          <div style="float: left; padding-top: 10px">
            <b-form-radio v-model="type" name="type" value="0">
              全部
            </b-form-radio>
          </div>
          <div style="float: left; padding-top: 10px; margin-left: 50px">
            <b-form-radio v-model="type" name="type" value="1"
              >个人
            </b-form-radio>
          </div>
          <div style="float: left; padding-top: 10px; margin-left: 50px">
            <b-form-radio v-model="type" name="type" value="2">
              群聊
            </b-form-radio>
          </div>
        </b-form-group>
        <b-form-group>
          <label for="deviceid">回复类型:</label>
          <b-form-select
            v-model="returnType"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="returnOptions"
            :reduce="(val) => val.value"
            :clearable="false"
            input-id="user-dname"
            @input="changeAcc"
          />
        </b-form-group>

        <b-form-group v-if="returnType == '文本'">
          <label for="module">回复内容:</label>
          <b-form-textarea
            id="module"
            placeholder="回复内容"
            autocomplete="off"
            v-model="returnString"
          />
        </b-form-group>
        <b-form-group v-if="returnType == '进群'">
          <label for="module">群号:</label>
          <b-form-input
            id="module"
            placeholder="群号"
            autocomplete="off"
            v-model="returnString"
          />
        </b-form-group>
        <b-form-group v-if="returnType == '链接'">
          <label for="module">链接:</label>
          <b-form-input
            id="module"
            placeholder="链接"
            autocomplete="off"
            v-model="linkurl"
          />

          <label for="module">标题:</label>
          <b-form-input
            id="module"
            placeholder="标题"
            autocomplete="off"
            v-model="linktitle"
          />

          <label for="module">描述:</label>
          <b-form-input
            id="module"
            placeholder="描述"
            autocomplete="off"
            v-model="linkdesc"
          />
          <label for="module">图标:</label>
          <b-form-input
            id="module"
            placeholder="图标"
            autocomplete="off"
            v-model="linkicon"
          />
        </b-form-group>
        <b-form-group v-if="returnType == '图片'">
          <label for="module">图片链接:</label>
          <b-form-input
            id="module"
            placeholder="图片链接"
            autocomplete="off"
            v-model="picurl"
          />
        </b-form-group>
        <b-form-group v-if="returnType == '视频'">
          <label for="module">视频链接:</label>
          <b-form-input
            id="module"
            placeholder="视频链接"
            autocomplete="off"
            v-model="videourl"
          />
        </b-form-group>
        <b-form-group v-if="returnType == '小程序'">
          <label for="module">小程序AppId:</label>
          <b-form-input
            id="module"
            placeholder="AppId"
            autocomplete="off"
            v-model="weappAppid"
          />
          <label for="module">小程序名称:</label>
          <b-form-input
            id="module"
            placeholder="AppId"
            autocomplete="off"
            v-model="weappsource"
          />
          <label for="module">小程序原始id:</label>
          <b-form-input
            id="module"
            placeholder="AppId"
            autocomplete="off"
            v-model="weappsourcename"
          />
          <label for="module">标题:</label>
          <b-form-input
            id="module"
            placeholder="标题"
            autocomplete="off"
            v-model="weapptitle"
          />

          <label for="module">描述:</label>
          <b-form-input
            id="module"
            placeholder="描述"
            autocomplete="off"
            v-model="weappdesc"
          />
          <label for="module">小程序图标:</label>
          <b-form-input
            id="module"
            placeholder="缩略图"
            autocomplete="off"
            v-model="weappicon"
          />
          <label for="module">缩略图URL:</label>
          <b-form-input
            id="module"
            placeholder="缩略图"
            autocomplete="off"
            v-model="weapppicurl"
          />
          <label for="module">路径:</label>
          <b-form-input
            id="module"
            placeholder="小程序路径"
            autocomplete="off"
            v-model="weapppath"
          />
        </b-form-group>
      </b-form>
    </b-modal>
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">search</label>
          <b-form-input
            v-model="searchTerm"
            placeholder="搜索"
            type="text"
            class="d-inline-block"
            @input="handleSearch"
          />
        </div>
      </b-form-group>
    </div>
    <!-- table -->
    <vue-good-table
      mode="remote"
      :columns="columns"
      :rows="rows"
      :rtl="direction"
      :search-options="{
        enabled: true,
        externalQuery: searchTerm,
      }"
      :select-options="{
        enabled: true,
        selectOnCheckboxOnly: true,
        selectionInfoClass: 'custom-class',
        selectionText: 'rows selected',
        clearSelectionText: 'clear',
        disableSelectInfo: true,
        selectAllByGroup: true,
      }"
      :pagination-options="{
        enabled: true,
      }"
      @on-sort-change="onSortChange"
    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: Name -->
        <span v-if="props.column.field === 'nickname'" class="text-nowrap">
          <b-avatar :src="props.row.avatar" class="mx-1" />
          <span class="text-nowrap">{{ props.row.nickname }}</span>
        </span>

        <!-- Column: tag -->

        <span v-else-if="props.column.field === 'type'">
          <b-badge :variant="gendersVariant(props.row.type)">
            {{ typeShow(props.row.type) }}
          </b-badge>
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'keyType'">
          <b-badge :variant="gendersVariant(props.row.keyType)">
            {{ keytypeShow(props.row.keyType) }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'createTime'">
          {{ timestampToTime(props.row.createTime) }}
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item>
                <router-link
                  :to="{ name: 'WechatEdit', params: { id: props.row.id } }"
                >
                  <feather-icon icon="Edit2Icon" class="mr-50" />
                  <span>编辑</span>
                </router-link>
              </b-dropdown-item>
              <b-dropdown-item @click="confirmText(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50" />
                <span>删除</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <!-- page length -->
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> 显示 </span>
            <b-form-select
              v-model="pageLength"
              :options="pages"
              class="mx-1"
              @input="handlePageChange"
            />
            <span class="text-nowrap"> {{ total }} 条 </span>
          </div>

          <!-- pagination -->
          <div>
            <b-pagination
              :value="1"
              :total-rows="total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @change="handleChangePage"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </b-card-code>
</template>

<script>
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BAvatar,
  BBadge,
  BButton,
  BPagination,
  BFormGroup,
  BFormTextarea,
  BFormInput,
  BFormSelect,
  BDropdownItem,
  BDropdown,
  BForm,
  BFormRadio,
  BModal,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { VueGoodTable } from "vue-good-table";
import "prismjs";
import "prismjs/themes/prism-tomorrow.css";
import Prism from "vue-prism-component";
import store from "@/store/index";
import useJwt from "@/auth/jwt/useJwt";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BCardCode,
    VueGoodTable,
    BAvatar,
    BBadge,
    BButton,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BFormTextarea,
    BDropdownItem,
    BDropdown,
    vSelect,
    BFormRadio,
    BForm,
    BModal,
    VBModal,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 10,
      total: 0,
      cid: 0,
      aid: 0,
      desc: "",
      search: "",
      type: "0",
      keyType: "0",
      dname: "",
      roleOptions: [],
      roleSelect: "",
      returnType: "文本",
      returnOptions: ["文本", "链接", "进群", "图片", "视频", "小程序"],
      returnString: "",
      accountid: "",
      linkurl: "",
      linktitle: "",
      linkdesc: "",
      linkicon: "",
      weappAppid: "",
      weapptitle: "",
      weappdesc: "",
      weapppicurl: "",
      weapppath: "",
      weappsource: "",
      weappsourcename: "",
      weappicon: "",
      picurl: "",
      videourl: "",
      dir: false,
      keyWord: "",
      pages: ["10", "20", "40", "100"],
      columns: [
        {
          label: "部门",
          field: "dname",
        },
        {
          label: "关键词",
          field: "keyWord",
        },
        {
          label: "匹配类型",
          field: "keyType",
        },
        {
          label: "回复类型",
          field: "returnType",
        },
        {
          label: "回复类容",
          field: "returnString",
        },
        {
          label: "类型",
          field: "type",
        },

        {
          label: "添加时间",
          field: "createTime",
        },
        {
          label: "操作",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  computed: {
    gendersVariant() {
      const gendersColor = {
        /* eslint-disable key-spacing */
        0: "light-primary",
        1: "light-success",
        /* eslint-enable key-spacing */
      };
      return (genders) => gendersColor[genders];
    },
    keytypeShow() {
      const typeVariant = {
        0: "精准",
        1: "模糊",
      };
      return (status) => typeVariant[status];
    },
    typeShow() {
      const typeVariant = {
        0: "全部",
        1: "个人",
        2: "群聊",
      };
      return (status) => typeVariant[status];
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  created() {
    const user = JSON.parse(localStorage.getItem("userData"));
    console.log("user", user);
    this.cid = user.cid;
    this.aid = user.id;
    useJwt
      .keyWordspageList({
        cid: this.cid,
        page: 1,
        rows: this.pageLength,
        desc: this.desc,
      })
      .then((res) => {
        // pagelength 一页显示多少条
        console.log("res", res);
        this.total = res.data.data.records;
        this.rows = res.data.data.rows;
      });
  },
  methods: {
    timestampToTime(cjsj) {
      const date = new Date(cjsj); // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      const Y = `${date.getFullYear()}-`;
      const M = `${
        date.getMonth() + 1 < 10
          ? `0${date.getMonth() + 1}`
          : date.getMonth() + 1
      }-`;
      const D = `${
        date.getDate() > 9 ? date.getDate() : "0" + date.getDate()
      } `;
      const h = `${
        date.getHours() > 9 ? date.getHours() : "0" + date.getHours()
      }:`;
      const m = `${
        date.getMinutes() > 9 ? date.getMinutes() : "0" + date.getMinutes()
      }:`;
      const s =
        date.getSeconds() > 9 ? date.getSeconds() : "0" + date.getSeconds();
      return Y + M + D + h + m + s;
    },
    indexSelect(value) {
      let obj = {};
      obj = this.accountOptions.find((item) => item.label === value);
      if (obj != null) {
        this.accountid = obj.value;
      }
    },
    alert() {
      this.dname = this.roleSelect;
      this.keyWord;
      console.log("dname", this.dname);
      console.log("keyWord", this.keyWord);
      console.log("keyType", this.keyType);
      console.log("returnType", this.returnType);
      console.log("type", this.type);
      if (this.returnType == "链接") {
        this.returnString = {
          Url: this.linkurl,
          Des: this.linkdesc,
          Title: this.linktitle,
          Thumb: this.linkicon,
        };
      }
      if (this.returnType == "图片") {
        this.returnString = this.picurl;
      }

      if (this.returnType == "视频") {
        this.returnString = this.videourl;
      }
      if (this.returnType == "小程序") {
        this.returnString = {
          AppId: this.weappAppid,
          Des: this.weappdesc,
          Title: this.weapptitle,
          Thumb: this.weapppicurl,
          Icon: this.weappicon,
          PagePath: this.weapppath,
          Source: this.weappsource,
          SourceName: this.weappsourcename,
        };
      }
      console.log("returnString", this.returnString);
      useJwt
        .keyWordsadd({
          did: this.roleSelect,
          cid: this.cid,
          keyType: this.keyType,
          keyWord: this.keyWord,
          returnString: JSON.stringify(this.returnString),
          accountId: this.aid,
          type: this.type,
          returnType: this.returnType,
        })
        .then((res) => {
          console.log("res", res);
          if (res.data.code === 0) {
            this.$swal({
              icon: "success",
              title: "添加成功!",
              text: "添加成功",
              customClass: {
                confirmButton: "btn btn-success",
              },
            });
            useJwt
              .keyWordspageList({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
              })
              .then((res2) => {
                this.total = res2.data.data.records;
                this.rows = res2.data.data.rows;
              });
          }
        });
    },
    changeAcc(newVal) {
      console.log(newVal);
      this.returnSelect = newVal;
    },
    confirmText(id) {
      this.$swal({
        title: "确定要删除吗?",
        text: "删除后无法恢复!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          useJwt.keyWordsdeletes({ ids: id }).then((res1) => {
            if (res1.data.code === 0) {
              this.$swal({
                icon: "success",
                title: "删除!",
                text: "删除成功",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              useJwt
                .keyWordspageList({
                  cid: this.cid,
                  page: 1,
                  rows: this.pageLength,
                })
                .then((res2) => {
                  this.total = res2.data.data.records;
                  this.rows = res2.data.data.rows;
                });
            } else {
              this.$swal({
                icon: "error",
                title: "删除失败!",
                text: "请联系管理员处理",
                customClass: {
                  confirmButton: "btn btn-error",
                },
              });
            }
          });
        }
      });
    },
    handleSearch(searching) {
      useJwt
        .queryFriendsAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          nickname: searching,
        })
        .then((res) => {
          this.total = res.data.data.records;
          if (this.total == 0) {
            useJwt
              .queryFriendsAll({
                cid: this.cid,
                page: 1,
                rows: this.pageLength,
                friendid: searching,
              })
              .then((res) => {
                this.total = res.data.data.records;
                if (this.total == 0) {
                  useJwt
                    .queryFriendsAll({
                      cid: this.cid,
                      page: 1,
                      rows: this.pageLength,
                      cname: searching,
                    })
                    .then((res) => {
                      this.total = res.data.data.records;
                      if (this.total == 0) {
                        useJwt
                          .queryFriendsAll({
                            cid: this.cid,
                            page: 1,
                            rows: this.pageLength,
                            accnickname: searching,
                          })
                          .then((res) => {
                            this.total = res.data.data.records;
                            this.rows = res.data.data.rows;
                          });
                      }
                    });
                }
                this.rows = res.data.data.rows;
              });
          }
          this.rows = res.data.data.rows;
        });
      this.search = searching;
      console.log(searching);
    },
    handleChangePage(page) {
      useJwt
        .queryFriendsAll({
          cid: this.cid,
          page: page,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      console.log("翻页" + this.search);
    },
    handlePageChange(active) {
      useJwt
        .queryFriendsAll({
          cid: this.cid,
          page: 1,
          rows: active,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          // pagelength 一页显示多少条
          this.pageLength = active;
          this.rows = res.data.data.rows;
          this.total = res.data.data.records;
        });
    },
    onSortChange(params) {
      this.desc = `${params[0].field}`;
      if (this.desc == "createTime") {
        this.desc = "create_time" + ` ${params[0].type}`;
      } else {
        this.desc = `${params[0].field} ${params[0].type}`;
      }
      console.log(this.desc);
      useJwt
        .queryFriendsAll({
          cid: this.cid,
          page: 1,
          rows: this.pageLength,
          desc: this.desc,
          nickname: this.search,
        })
        .then((res) => {
          this.total = res.data.data.records;
          this.rows = res.data.data.rows;
        });
      console.log(`${params[0].field} ${params[0].type}`);
    },
  },
  mounted() {
    // console.log("加载");
    useJwt.queryByCid({ cid: this.cid }).then((response) => {
      if (response.data.code == 0) {
        const forArr = response.data.data;
        console.log("forArr", forArr);
        forArr.forEach((item, i) => {
          this.roleOptions.push({ text: item.name, value: item.id });
        });
      }
    });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
